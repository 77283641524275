import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"


class Contact extends React.Component {
  render() {
    const siteTitle = "Atul Prd"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Get in touch"
          keywords={[`Blog`, `Product Design`, `Branding`, `UX Design`, `Product Designer`, `UX/UI`, `App Design`, `Brand Identity`]}
        />
        <h1 style={{ fontWeight: 800, marginTop: 20 }} >Contact</h1>
        <h3 style= {{ lineHeight: 1.5 , marginTop: `30px`, marginBottom: `20px` }}>
       Got a question? Have a project is mind? Want to say hi?
        </h3>
        <p>
        I’m always looking to grab coffee with interesting people, so feel free to send me an email at <a href={"mailto:hi@atulprd.com"} target="_blank" rel="noopener noreferrer">hi@atulprd.com</a> and I’ll get back to you as soon as I can.
        </p>
        <a href={"mailto:hi@atulprd.com"} target="_blank" rel="noopener noreferrer">
          <Button marginTop="35px">Get in touch</Button>
    </a>
      </Layout>
    )
  }
}

export default Contact
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
